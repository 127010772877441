<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      :page.sync="page"
      :permission="permissionList"
      :before-open="beforeOpen"
      v-model="form"
      ref="crud"
      @row-update="rowUpdate"
      @row-save="rowSave"
      @row-del="rowDel"
      @search-change="searchChange"
      @search-reset="searchReset"
      @selection-change="selectionChange"
      @current-change="currentChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @on-load="onLoad"
    >
      <template slot="menuLeft">
        <el-button
          type="danger"
          size="small"
          icon="el-icon-delete"
          plain
          v-if="permission.equipment - config_delete"
          @click="handleDelete"
          >删 除
        </el-button>
        <!-- <el-button
          type="primary"
          size="small"
          icon="el-icon-download"
          @click="handleExportExcel"
          >导出</el-button
        > -->
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import {
  getEquipList,
  getDetail,
  add,
  update,
  remove,
  getEntity,
} from "@/api/uav/equipmentConfig";
import { getBrandList } from "@/api/uav/brandManagement";
import { mapGetters } from "vuex";
import { exportExcel } from "@/util/export";

export default {
  name: 'equipment-manager',
  data() {
    return {
      form: {},
      query: {},
      loading: true,
      brandList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      option: {
        height: "auto",
        calcHeight: 30,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        columnBtn: false,
        labelWidth: 110,
        excelBtn: true,
        excelFields: ['equipmentName', 'serialNumber', 'equipmentType','uavPower','equipmentNumber','manufacturerName','teamidStr','createUser'], // 控制导出的列
        searchBtnText: "搜索",
        column: [
          {
            label: "设备名称",
            prop: "equipmentName",
            search: true,
            display: false,
            rules: [
              {
                required: true,
                message: "请输入设备名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "序列号",
            prop: "serialNumber",
            display: false,
            search: true,
            rules: [
              {
                required: true,
                message: "请输入设备序列号",
                trigger: "blur",
              },
            ],
          },
          {
            label: "设备类型",
            prop: "equipmentType",
            dicUrl:
              "/api/blade-system/dict/dictionary-tree?code=equipment_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            type: "tree",
            display: false,
            search: true,
            multiple: true,
            dicFormatter: (res) => {
              if (res.data) {
                for (let i in res.data) {
                  if (res.data[i].children.length !== 0) {
                    res.data[i].disabled = true;
                  }
                }
                return res.data;
              } else {
                return [];
              }
            },
            rules: [
              {
                required: true,
                message: "请选择设备类型",
                trigger: "blur",
              },
            ],
          },
          {
            label: "无人机动力",
            prop: "uavPower",
            dicUrl: "/api/blade-system/dict/dictionary?code=uav_power",
            type: "select",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            display: false,
          },
          {
            label: "无人机起飞重量(单位：kg)",
            prop: "takeoffWeight",
            display: false,
            hide: true,
          },
          {
            label: "无人机载重(单位：kg)",
            prop: "droneLoad",
            display: false,
            hide: true,
          },
          {
            label: "最快飞行速度(单位：km/h)",
            prop: "fastestSpeed",
            display: false,
            hide: true,
          },
          {
            label: "设备型号",
            prop: "equipmentNumber",
            // search: true,
            display: false,
            // searchslot: true,
            rules: [
              {
                required: true,
                message: "设备型号",
                trigger: "blur",
              },
            ],
          },
          {
            label: "默认关联载荷",
            prop: "podId",
            type: "select",
            dicUrl:
              "/api/cloud-equipment/equipment-management/findDefaultEquipment?equipmentType=pod",
            display: false,
            hide: true,
            props: {
              label: "equipmentName",
              value: "id",
            },
          },
          {
            label: "默认关联数据链",
            prop: "dataLinkId",
            display: false,
            hide: true,
            // addDisplay: true,
            // editDisplay: true,
            type: "select",
            dicUrl:
              "/api/cloud-equipment/equipment-management/findDefaultEquipment?equipmentType=datalink",
            // display: false,
            // searchslot: true,
            // searchLabelWidth: 120,
            props: {
              label: "equipmentName",
              value: "id",
            },
          },
          {
            label: "品牌名称",
            prop: "manufacturerName",
            // hide: true,
            type: "select",
            search: true,
            display: false,
            exportDispaly: false,
            props: {
              label: "name",
              value: "id",
            },
            dicData: [],
            rules: [
              {
                required: true,
                message: "请选择品牌名称",
                trigger: "blur",
              },
            ],
          },
          // {
          //   label: "品牌名称",
          //   prop: "manufacturerName",
          //   addDisplay: false,
          //   // editDisplay: true,
          //   display: false,
          //   search: true,
          //   type: "select",
          //   // hide:true,
          //   // dicData: [],
          //   props: {
          //     label: "name",
          //     value: "name",
          //   },
          // },
          // {
          //   label: "当前任务",
          //   prop: "taskName",
          //   display: false,
          //   rules: [
          //     {
          //       required: true,
          //       message: "请输入当前任务名",
          //       trigger: "blur",
          //     },
          //   ],
          // },
          // {
          //   label: "当前项目",
          //   prop: "projectName",
          //   display: false,
          //   rules: [
          //     {
          //       required: true,
          //       message: "请输入当前项目名",
          //       trigger: "blur",
          //     },
          //   ],
          // },
          {
            label: "所属团队",
            prop: "deptIdList",
            type: "tree",
            multiple: true,
            hide: true,
            checkStrictly: true,
            dicUrl: "/api/blade-system/dept/tree",
            props: {
              label: "title",
              value: "value"
            },
            search: true,
            display: false,
            showColumn: false,
            rules: [
              {
                required: true,
                message: "请选择所属团队",
                trigger: "click",
              },
            ],
          },

          {
            label: "所属团队",
            prop: "deptNames",
            addDisplay: false,
            // editDisplay: true,
            display: false,
            // search: true,
            type: "select",
            // hide:true,
            // dicData: [],
            props: {
              label: "name",
              value: "name",
            },
          },
          {
            label: "设备状态",
            prop: "equipmentStatus",
            // hide: true,
            type: "select",
            search: true,
            display: false,
            dicUrl: "/api/blade-system/dict/dictionary-tree?code=equipmentStatus",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            rules: [
              {
                required: true,
                message: "请选择设备状态",
                trigger: "blur",
              },
            ],
          },

          {
            label: "创建人",
            prop: "createUser",
            type: "select",
            dicUrl: `/api/blade-user/user-list`,
            props: {
              label: "account",
              value: "id",
            },
            addDisplay: false,
            editDisplay: false,
            viewDisplay: false,
            span: 12,
            rules: [
              {
                message: "请选择创建人",
                trigger: "blur",
              },
            ],
          },
          {
            label: "物联网卡号",
            prop: "networkCardNumber",
            display: false,
            hide: true,
            rules: [
              {
                required: true,
                message: "请输入网卡编号",
                trigger: "blur",
              },
            ],
          },
        ],
        group: [
          {
            label: "关联设备",
            prop: "dataLink",
            icon: "el-icon-setting",
            addDisplay: false,
            editDisplay: false,
            viewDisplay: false,
            column: [
              {
                label: "默认关联载荷",
                prop: "podId",
                type: "select",
                // addDisplay: false,
                // editDisplay: false,
                dicUrl:
                  "/api/cloud-equipment/equipment-management/findDefaultEquipment?equipmentType=pod",
                props: {
                  label: "equipmentName",
                  value: "id",
                },
                rules: [
                  {
                    // required: true,
                    message: "请选择默认关联载荷",
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "默认关联数据链",
                prop: "dataLinkId",
                type: "select",
                // addDisplay: false,
                // editDisplay: false,
                dicUrl:
                  "/api/cloud-equipment/equipment-management/findDefaultEquipment?equipmentType=datalink",
                props: {
                  label: "equipmentName",
                  value: "id",
                },
                rules: [
                  {
                    message: "请选择默认关联数据链",
                    trigger: "blur",
                  },
                ],
              },
            ],
          },
          {
            label: "基础信息",
            prop: "baseInfo",
            icon: "el-icon-user-solid",
            column: [
              {
                label: "品牌名称",
                prop: "manufacturerId",
                type: "select",
                cascader: ["equipmentNumber"],
                props: {
                  label: "name",
                  value: "id",
                },
                rules: [
                  {
                    required: true,
                    message: "请选择品牌名称",
                    trigger: "blur",
                  },
                ],
                control: (val, form) => {
                  const brand = this.brandList.find((b) => {
                    return b.id === val;
                  });
                  if (brand) {
                    this.form.manufacturerCode = brand.code;
                    this.form.manufacturerType = brand.type;
                  }
                  // 类别(1-关联实体中心；2-不关联实体中心)--
                  if (brand && brand.type === 1) {
                    // console.log(this.option.group,"control");
                    this.option.group[0].addDisplay = true;
                    this.option.group[0].editDisplay = true;
                    this.option.group[0].viewDisplay = true;
                    return {
                      equipmentType: {
                        addDisabled: true,
                        editDisabled: true,
                      },
                      equipmentModel: {
                        addDisabled: false,
                        editDisabled: false,
                      },
                    };
                  } else {
                    this.option.group[0].addDisplay = false;
                    this.option.group[0].viewDisplay = false;
                    this.option.group[0].editDisplay = false;
                    return {
                      equipmentNumber: {
                        addDisabled: false,
                        editDisabled: false,
                      },
                      equipmentType: {
                        addDisabled: false,
                        editDisabled: false,
                      },
                      equipmentModel: {
                        display: false,
                      },
                    };
                  }
                },
              },
              {
                label: "品牌Code",
                prop: "manufacturerCode",
                display: false,
              },
              {
                label: "品牌类型",
                prop: "manufacturerType",
                display: false,
              },
              {
                label: "设备类型",
                prop: "equipmentType",
                type: "tree",
                cascader: ["equipmentNumber"],
                dicUrl:
                  "/api/blade-system/dict/dictionary-tree?code=equipment_type",
                props: {
                  label: "dictValue",
                  value: "dictKey",
                },
                dicFormatter: (res) => {
                  if (res.data) {
                    for (let i in res.data) {
                      if (res.data[i].children.length !== 0) {
                        res.data[i].disabled = true;
                      }
                    }
                    return res.data;
                  } else {
                    return [];
                  }
                },
                popperClass: "popperClass",
                rules: [
                  {
                    required: true,
                    message: "请选择设备类型",
                    trigger: "blur",
                  },
                ],
                addDisabled: true,
                editDisabled: true,
                control: (val, form) => {
                  if (typeof val == "string") {
                    if (val.includes("uav")) {
                      return {
                        uavPower: {
                          display: true,
                        },
                        takeoffWeight: {
                          display: true,
                        },
                        droneLoad: {
                          display: true,
                        },
                        fastestSpeed: {
                          display: true,
                        },
                      };
                    } else {
                      return {
                        uavPower: {
                          display: false,
                        },
                        takeoffWeight: {
                          display: false,
                        },
                        droneLoad: {
                          display: false,
                        },
                        fastestSpeed: {
                          display: false,
                        },
                      };
                    }
                  }
                },
              },
              {
                label: "设备型号",
                prop: "equipmentNumber",
                type: "select",
                dicUrl: `/api/blade-system/dict-biz/getDictBizByParentKey?childValue={{equipmentType}}&parentKey={{manufacturerCode}}`,
                props: {
                  label: "dictKey",
                  value: "dictKey",
                },
                rules: [
                  {
                    required: true,
                    message: "请输入设备型号",
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "实体指纹",
                prop: "equipmentModel",
                props: {
                  label: "dictValue",
                  value: "dictKey",
                },
                rules: [
                  {
                    required: true,
                    message: "请输入实体指纹",
                    trigger: "blur",
                  },
                ],
                addDisabled: true,
                editDisabled: true,
                blur: ({ value, column }) => {
                  const brand = this.brandList.find((b) => {
                    return b.id === this.form.manufacturerId;
                  });

                  if (brand && brand.type === 1 && value) {
                    this.getEntity(value);
                  }
                },
              },
              {
                label: "设备名称",
                prop: "equipmentName",
                rules: [
                  {
                    required: true,
                    message: "请输入设备名称",
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "所属团队",
                prop: "deptIdList",
                type: "tree",
                multiple: true,
                checkStrictly: true,
                dicUrl: "/api/blade-system/dept/tree",
                props: {
                  label: "title",
                  value: "value",
                },
                showColumn: false,
                rules: [
                  {
                    required: true,
                    message: "请选择所属团队",
                    trigger: "click",
                  },
                ],
              },
              {
                label: "设备序列号",
                prop: "serialNumber",
                rules: [
                  {
                    required: true,
                    message: "请输入序列号",
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "设备状态",
                prop: "equipmentStatus",
                type: "select",
                dicUrl: "/api/blade-system/dict/dictionary-tree?code=equipmentStatus",
                props: {
                  label: "dictValue",
                  value: "dictKey",
                },
                rules: [
                  {
                    required: true,
                    message: "请选择设备状态",
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "无人机动力",
                prop: "uavPower",
                dicUrl: "/api/blade-system/dict/dictionary?code=uav_power",
                type: "select",
                props: {
                  label: "dictValue",
                  value: "dictKey",
                },
                display: true,
              },
              {
                label: "无人机起飞重量(单位：kg)",
                prop: "takeoffWeight",
                display: true,
              },
              {
                label: "无人机载重(单位：kg)",
                prop: "droneLoad",
                display: true,
              },
              {
                label: "最快飞行速度(单位：km/h)",
                prop: "fastestSpeed",
                display: true,
              },
              {
                label: "物联网卡号",
                prop: "networkCardNumber",
              },
              {
                label: "设备所在地",
                prop: "equipmentLocation",
                // slot: true,
                type: "map",
              },
              {
                label: "创建人",
                prop: "createUser",
                type: "select",
                dicUrl: `/api/blade-user/user-list`,
                props: {
                  label: "account",
                  value: "id",
                },
                hide: true,
                addDisplay: false,
                editDisplay: false,
                span: 12,
                rules: [
                  {
                    message: "请选择创建人",
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "UTMISS",
                prop: "uaSid"
              }, 
              {
                label: "备注",
                prop: "remark",
                type: "textarea",
              },
              {
                label: "设备图片",
                prop: "equipmentImageUrl",
                hide: true,
                // display: false,
                dataType: "string",
                type: "upload",
                listType: "picture-img",
                span: 24,
                propsHttp: {
                  res: "data",
                  url: "link",
                  name: "originalName",
                },
                tip: "大小限制:5MB请上传清晰正确的设备照片,更好的展示设备信息",
                action: "/api/blade-resource/oss/endpoint/upload-file",
              },
            ],
          },
          {
            label: "飞行统计",
            prop: "detailInfo",
            icon: "el-icon-s-order",
            addDisplay: false,
            editDisplay: false,
            column: [
              {
                label: "当前任务",
                prop: "taskName",
                editDisabled: true,
              },
              {
                label: "任务累计飞行",
                prop: "flightDistance",
                editDisplay: false,
                display: false,
              },
              {
                label: "设备状态",
                prop: "equipmentStatusName",
                editDisabled: true,
              },
              {
                label: "总架次",
                prop: "flightTime",
                editDisplay: false,
                display: false,
              },
            ],
          },
        ]
      },
      data: [],
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.equipmentConfig_add, true),
        viewBtn: this.vaildData(this.permission.equipmentConfig_view, true),
        delBtn: this.vaildData(this.permission.equipmentConfig_delete, true),
        editBtn: this.vaildData(this.permission.equipmentConfig_edit, true),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },


    created(){
      getBrandList().then((res) => {
          const data = res.data.data.records;
          this.option.group[1].column[0].dicData = data;
          this.brandList=data;
          this.$set(this.option.column[10], 'dicData', data.map(item=>{
            return {
              name: item.name,
              id: item.name
            }
          }))
        });

    },

  methods: {
    // 导出功能
    handleExportExcel() {
      const option = {
        title: "设备管理信息",
        columns: [
          {
            label: "设备名称",
            prop: "equipmentName",
          },
          {
            label: "设备序列号",
            prop: "serialNumber",
          },
          {
            label: "设备类型",
            prop: "equipmentType",
            dicUrl:
              "/api/blade-system/dict/dictionary-tree?code=equipment_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            render: row => _this.$AVUE.hooks.dictText(this.columns[2], row[this.columns[2].prop])


          },
          {
            label: "无人机动力",
            prop: "uavPower",
          },
          {
            label: "设备型号",
            prop: "equipmentNumber",
          },
          {
            label: "品牌名称",
            prop: "manufacturerName",
          },
          {
            label: "所属团队",
            prop: "deptIds",
          },
          {
            label: "创建人",
            prop: "createUser",
            dicUrl: `/api/blade-user/user-list`,
                props: {
                  label: "account",
                  value: "id",
              },
          },
        ],
      };
      const _this = this;
      exportExcel(option, _this);
    },

    ifShowRelationEquip(val) {
      this.$nextTick(() => {
        let realationEquip = this.findObject(
          this.option.column,
          "relationEquipmentIds"
        );
        if (val === "air" || val === "") {
          realationEquip.display = false;
        } else {
          realationEquip.display = true;
        }
      });
    },
    ifShowType(val) {
      this.$nextTick(() => {
        let typeColumn = this.findObject(this.option.column, "uavAirType");
        if (val === "air") {
          typeColumn.display = true;
          typeColumn.rules[0].required = true;
        } else {
          typeColumn.display = false;
          typeColumn.rules[0].required = false;
        }
      });
    },


    // 获取实体详情
    getEntity(value) {
      getEntity({ signature: value }).then((res) => {
        if (res.data.code === 200) {
          const equipmentType = res.data.data;
          this.$nextTick(() => {
            this.form.equipmentType = equipmentType;
          });
        }
      });
    },

    rowSave(row, done, loading) {
      row.equipmentLocation = row.equipmentLocation.join(",");
      add(row).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          loading();
          window.console.log(error);
        }
      );
    },
    rowUpdate(row, index, done, loading) {
      row.equipmentLocation = row.equipmentLocation.join(",");
      update(row).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          loading();
          console.log(error);
        }
      );
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.$refs.crud.toggleSelection();
        });
    },
    beforeOpen(done, type) {
      this.$refs.crud.updateDic("relationEquipmentIds");
      if (["edit", "view"].includes(type)) {
        getDetail(this.form.id).then((res) => {
          this.form = res.data.data;
          this.ifShowRelationEquip(this.form.equipmentType);
        });
      }
      done();
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      if (this.$refs.crud) {
          this.$refs.crud.toggleSelection();
      }
    },
      
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      getEquipList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionClear();
      });
    },
  },
};
</script>

<style>
::v-deep .el-card__body{
  height: 100% ;
}
</style>
